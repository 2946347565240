import { createAsyncThunk } from '@reduxjs/toolkit';
import apiKey from '../../../utils/axiosInstance';
export const getAllComments = createAsyncThunk('comment/getAllComments', async ({ commandId }, ThunkApi) => {
    try {
        const response = await apiKey.get(`comments/${commandId}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const createComment = createAsyncThunk('comment/createComment', async ({ params }, ThunkApi) => {
    try {
        const { commandId, commentData } = params;
        const response = await apiKey.post(`comments/${commandId}/comment`, commentData);
        const data = response.data;
        return data;
    }
    catch (error) {
        return Promise.reject(error.message ? error.message : 'something went wrong');
    }
});
