import { createSlice } from '@reduxjs/toolkit';
import { createComment, getAllComments } from './commentThunk';
const initialState = {
    comments: [],
    isLoadingAllComments: false,
    isLoadingCreateComment: false,
    error: null,
};
const slice = createSlice({
    name: 'comments',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllComments.pending, (state) => {
            state.isLoadingAllComments = true;
        })
            .addCase(getAllComments.fulfilled, (state, { payload }) => {
            state.isLoadingAllComments = false;
            state.comments = payload.data.comments;
        })
            .addCase(getAllComments.rejected, (state) => {
            state.error = true;
            state.isLoadingAllComments = false;
        })
            .addCase(createComment.pending, (state) => {
            state.isLoadingCreateComment = true;
        })
            .addCase(createComment.fulfilled, (state, { payload }) => {
            state.isLoadingCreateComment = false;
        })
            .addCase(createComment.rejected, (state) => {
            state.isLoadingCreateComment = false;
        });
    },
});
// Reducer
export default slice.reducer;
